.pclass{
    width: 1000px;
    display: flex;
    margin-left: 10px;

}
.pclass1{
    margin-left: 26%;
}

@media screen and(max-width: 663) {

    .pclass1{
        display: none;
    }
    .pclass{
        display: none;
    }
}
