.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width:640px) {
  #mainslider button{
    display: none;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar{
  box-shadow: 0px 0px 8px -2px black;
}

.footer
{
  box-shadow: 0px 0px 8px -2px black;
}
.related
{
  box-shadow: 0px 0px 8.052555084228516px 5.751824855804443px #00000040;
  background-color: white;
  z-index: 100;
}
#root
{
  display:flex;
  flex-direction:column;
  min-height:100vh;
  justify-content:space-between;
}
/* p, h1, h2, h3, h4, h5, h6
{
  text-align:justify;
} */

.react-pdf__Page__textContent
{
  display:none;
}
.react-pdf__Page__annotations
{
  display:none;
}
.react-pdf__Page__canvas
{
width:100% !important;
}
canvas{
  width: 100% !important;
  height: 100% !important;
}
@media (min-width: 786px) {
  canvas{
    width: 80% !important;
    height: 80vh !important;
  }
}