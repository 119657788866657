.div1{
    margin-top: 40px;
}

.div2{
    /* card py-10 px-5  text-center   bg-[#315ED2] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-around gap-5 */
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
    background-color: #315ED2;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-content: space-around;
    gap: 1.25rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.gallery {
  margin-top: 80px;
  width: 80%;
  height: 700px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
}


.gallery-image {
  margin-top: 8%; 
  width: 30%;
  height: calc(50% - 20px);
  margin: 10px;
  overflow: hidden;
  flex-wrap: wrap;
}


.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 2s;
}

.image-name,
.close-btn,
.index,
.large-image,
.arrow-btn {
  opacity: 0;
}


.popup {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 60%;
  max-width: 1600px;
  height: 800px;
  max-height: 800px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  overflow: hidden;
  transition: 1s;
}


.popup.active .close-btn,
.popup.active .image-name,
.popup.active .index,
.popup.active .large-image,
.popup.active .arrow-btn {
  opacity: 1;
}

.top-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  background: #000;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-weight: 300;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f00;
  cursor: pointer;
}

.arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  border-radius: 50%;
  border: none;
  background: none;
  cursor: pointer;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
  transform: translateY(-50%);
}

.img1 {
  height: 20px;
  width: 20px;
}

.arrow-btn:hover {
  background: rgba(0, 0, 0, 0.5);
}

.index {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 80px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.4);
}

.large-image {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.popup.active {
  transform: translate(-50%, -50%) scale(1);
}


@media screen and (max-width: 750px) {

  .img1{
    height: 140px;
    width: 140px;
  }

 .galery{
  border: 0.4px double;
  width: 100%;
  margin-left: 0%;
 }

 .gallery-image{
  width:  33%;
  height: 20%;
  margin-left: 0%;
  border: 0.2px double;
 }
  .img1{
    height: 20px;
    width: 20px;
  }
  .popup{
    height: 400px;
    width: 100%;
  }
}
